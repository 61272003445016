jQuery(function () {
    try {
        var myOffside = offside("#mobileMenu", {
            slidingElementsSelector: "body",
            buttonsSelector:
                ".menu-toggle, .offcanvas-close, .offcanvas-overlay",
            beforeOpen: function () {
                $(".offcanvas-overlay").fadeIn();
            },
            beforeClose: function () {
                $(".offcanvas-overlay").fadeOut();
            },
        });
    } catch {}

    if ($(window).width() > 1024) {
        $("header").toggleClass("scrolling", $(window).scrollTop() > 0);

        $(window).on("scroll", function () {
            $("header").toggleClass("scrolling", $(window).scrollTop() > 0);
            $(".header-logo").toggleClass(
                "scrolling",
                $(window).scrollTop() > 0
            );
        });
    } else {
        $("header").sticky({
            topSpacing: 0,
            zIndex: 999,
        });
    }

    $(".back-to-top").on("click", function () {
        $("html, body").animate({ scrollTop: 0 }, 2000);
    });

    if($('body').hasClass('homepage')){
        AOS.init({
            offset: 75,
            duration: 700
        });
    }

    roomMediaSlider();
    heroGallerySlider();

    $(".hero-tabs").tabslet({
        container: "#hero-tab-container",
    });

    $(".room-media-tab").tabslet();

    $("body").on("click", "a.ajaxpagerlink", function (e) {
        e.preventDefault();
        var obj = $(this);
        var pageurl = $(this).attr("href");
    
        $.ajax({
            url: pageurl,
            data: { isajax: true },
            success: function (data) {
                $(".ajaxresponse .ajaxresponsewrap").append(
                    $(data).find(".ajaxresponsewrap").html()
                );
                obj.remove(); //remove old
                $(".ajaxresponse .ajaxpagerlinkwrap").append(
                    $(data).find(".ajaxpagerlinkwrap").html()
                ); //add new
            },
        });
    
        //to change the browser URL to 'pageurl'
        // if (pageurl != window.location) {
        //     window.history.pushState({ path: pageurl }, "", pageurl);
        // }
    
        return false;
    });

    $("[background]").each(function () {
        var background = $(this).attr("background");
        $(this).css({
            "background-image": "url(" + background + ")",
        });
    });

    $.fancybox.defaults.backFocus = false;
    
});


try {
    const menuMapping = new MappingListener({
        selector: ".menu-wrapper",
        mobileWrapper: ".offcanvas-wrap",
        mobileMethod: "appendTo",
        desktopWrapper: ".header-bottom",
        desktopMethod: "appendTo",
        breakpoint: 1024.98,
    }).watch();
} catch {}

function heroGallerySlider() {
    $(".hero-gallery-slider").each(function (index) {
        var $this = $(this);
        $this.addClass("hero-gallery-slider-" + index);
        $this
            .find(".swiper-prev")
            .addClass("hero-gallery-slider-prev-" + index);
        $this
            .find(".swiper-next")
            .addClass("hero-gallery-slider-next-" + index);

        var heroGallerySlider = new Swiper(
            ".hero-gallery-slider-" + index + " .swiper-container",
            {
                slidesPerView: 1.55,
                spaceBetween: 145,
                centeredSlides: true,
                // loop: true,
                initialSlide: 1,
                navigation: {
                    prevEl: ".hero-gallery-slider-prev-" + index,
                    nextEl: ".hero-gallery-slider-next-" + index,
                },
                breakpoints: {
                    1280: {
                        spaceBetween: 75,
                    },
                    1024: {
                        spaceBetween: 20,
                    },
                    768: {
                        spaceBetween: 10,
                    },
                },
            }
        );
    });
}

function roomMediaSlider() {
    $(".room-media-slider").each(function (index) {
        var $this = $(this);
        $this.addClass("room-media-slider-" + index);
        $this.find(".swiper-prev").addClass("room-media-slider-prev-" + index);
        $this.find(".swiper-next").addClass("room-media-slider-next-" + index);

        var roomMediaSlider = new Swiper(
            ".room-media-slider-" + index + " .swiper-container",
            {
                slidesPerView: 1,
                spaceBetween: 20,
                loop: true,
                autoplay: {
                    delay: 4000
                },
                navigation: {
                    prevEl: ".room-media-slider-prev-" + index,
                    nextEl: ".room-media-slider-next-" + index,
                },
            }
        );
    });
}

const heroImageSlider = new Swiper(".hero-image-slider .swiper-container", {
    spaceBetween: 20,
    slidesPerView: 4,
    // loop: true,
    autoplay: {
        delay: 3000,
    },
    breakpoints: {
        1280: {
            spaceBetween: 10,
            slidesPerView: 3,
        },
        1024: {
            spaceBetween: 10,
            slidesPerView: 2.5,
        },
        768: {
            spaceBetween: 10,
            slidesPerView: 2.15,
        },
        768: {
            spaceBetween: 10,
            slidesPerView: 1.15,
        },
    },
});

const homeBannerSlider = new Swiper(".home-banner-slider .swiper-container", {
    autoplay: {
        delay: 4000,
    },
    speed: 1000,
    navigation: {
        prevEl: ".home-banner-slider .swiper-prev",
        nextEl: ".home-banner-slider .swiper-next"
    },
    effect: "fade",
    fadeEffect: {
        crossFade: true,
    },
});

const homeTextImageSlider = new Swiper(
    ".home-feature-text-slider .swiper-container",
    {
        autoplay: {
            delay: 4000,
            disableOnInteraction: false,
        },
        speed: 1500,
        loop: true,
        effect: "fade",
        fadeEffect: {
            crossFade: true,
        },
        navigation: {
            nextEl: ".home-2 .next",
        },
        allowTouchMove: false,
    }
);

const homeFeatureImageSlider = new Swiper(
    ".home-feature-image-slider .swiper-container",
    {
        autoplay: {
            delay: 4000,
            disableOnInteraction: false,
        },
        speed: 1500,
        loop: true,
        thumbs: {
            swiper: homeTextImageSlider,
        },
        pagination: {
            el: ".home-2 .swiper-pagination",
            type: "fraction",
        },
        navigation: {
            nextEl: ".home-2 .next",
        },
        allowTouchMove: false,
        on: {
            init: function () {
                $(".progress-inner").addClass("active");
            },
            slideNextTransitionStart: function () {
                $(".progress-inner").removeClass("active");
            },
            slideChangeTransitionEnd: function () {
                $(".progress-inner").addClass("active");
            },
        },
    }
);
